import React from 'react';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';
import MobileMenu2 from '../MobileMenu2/MobileMenu2';
import Logo from '../../images/logo.png';


const Header3 = ({ tournament, ...props }) => {
    // const [searchActive, setSearchState] = useState(false);
    // const [cartActive, setCartState] = useState(false);

    // const SubmitHandler = (e) => {
    //     e.preventDefault()
    // }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    return (
        <header className="main-header">
            {/* Header Upper */}
            <div className="header-upper">
                <div className="auto-container">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">

                        <div className="logo-box">
                            <div className="logo"><NavLink onClick={ClickHandler} to="/home"><img src={Logo} alt="" title="" /></NavLink></div>
                        </div>

                        <div className="nav-outer">
                            {/* Main Menu */}
                            <nav className="main-menu navbar-expand-md">
                                <div className="navbar-header">
                                    {/* Toggle Button */}
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>

                                <div className="navbar-collapse collapse clearfix" id="navbarSupportedContent">
                                    <ul className="navigation clearfix d-flexbox align-items-center">
                                        <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-100}>Home</Link></li>
                                        <li><a href='#about'> About </a></li>
                                        <li><a href='#next'>Next Event</a></li>
                                        <li><a href='#contact'>Contact</a></li>
                                        <li><a href='#partners'>Partners</a></li>
                                        <li><form action="https://www.paypal.com/donate" method="post" target="_top">
                                            <input type="hidden" name="hosted_button_id" value="DUGVT8NRKBJBQ" />
                                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                                        </form>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>

                        {/* Main Menu End*/}
                        <div className="outer-box d-flex align-items-center flex-wrap">

                            <div className="appointmeny_button-box">
                                {/* <NavLink onClick={ClickHandler} to="/register" className="theme-btn btn-style-one"> */}
                                <a className='theme-btn btn-style-one' href={`https://melee.gg/Tournament/View/${tournament.id}`} target="new">
                                    <span className="btn-wrap">
                                        <span className="text-one">Register</span>
                                        <span className="text-two">Register</span>
                                    </span>
                                </a>
                                {/* </NavLink> */}
                            </div>

                            {/* Mobile Navigation Toggler */}
                            <MobileMenu2 />
                        </div>

                    </div>
                </div>
            </div>
        </header>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         carts: state.cartList.cart,
//     };
// };


export default Header3;
// export default connect(mapStateToProps, { removeFromCart })(Header3);