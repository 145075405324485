import React from "react";
// import { Link } from 'react-router-dom'
// import pimg1 from '../../images/clients/tier1-logo.png';
// import pimg2 from '../../images/clients/2.png'
// import pimg3 from '../../images/clients/3.png'


// const partners = [
//     {
//         pImg: pimg1,
//         url: "www.setroulette.com/",
//     },
// ];


const PartnerSection = (props) => {

    return (

        <section className="clients-one" id="partners"
            style={{
                backgroundColor: 'var(--color-seven)',
            }}
        >
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Support Set Roulette</div>
                    <h2 className="sec-title_heading">Your donations keep Set Roulette spinning</h2>
                    <p
                        style={{
                            justifyContent: "space-around",
                            maxWidth: "900px",
                            textAlign:'left',
                            margin:'2rem auto 2rem auto',
                        }}
                    >
                        Set Roulette is a volunteer run, not-for-profit tournament series.  Here are a few of the ways that your donations help us keep this project running.</p>
                    <div className="d-flex align-items-center"
                        style={{
                            justifyContent: "space-around",
                            maxWidth: "680px",
                            margin: "auto"
                        }}
                    >

                        <form action="https://www.paypal.com/donate" method="post" target="_top">
                            <input type="hidden" name="hosted_button_id" value="DUGVT8NRKBJBQ" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                        <ul style={{
                            textAlign: 'left',
                        }}>
                            <li> Sustaining and growing prize support</li>
                            <li> Extending prize support to Europe and other regions </li>
                            <li> Paying site fees and maintenance </li>
                            <li> Engaging in marketing to grow our events </li>
                            <li> Purchasing streaming equipment to improve live broadcasts</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PartnerSection;