import React, { useState } from 'react'
// import { Link } from 'react-router-dom'
import ModalVideo from 'react-modal-video'
import abimg from '../../images/background/pattern-1.png'
import abimg2 from '../../images/background/pattern-2.png'
import abimg3 from '../../images/background/pattern-3.png'
import abimg4 from '../../images/background/about-pattern.png'
import abimg5 from '../..//images/resource/about.jpg'
import { Roll } from "react-awesome-reveal";

const About = ({tournament}) => {

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    const [isOpen, setOpen] = useState(false)

    return (
        <section className="about-one" id="next">
            <div className="about-one_pattern-one" style={{ backgroundImage: `url(${abimg})` }}></div>
            <div className="about-one_pattern-two" style={{ backgroundImage: `url(${abimg3})` }}></div>
            <div className="about-one_pattern-three" style={{ backgroundImage: `url(${abimg2})` }}></div>
            <div className="auto-container">
                <div className="row clearfix">
                    <div className="about-one_image-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_image-inner">
                            <div className="about-one_pattern-four" style={{ backgroundImage: `url(${abimg4})` }}></div>
                            <Roll left triggerOnce={'false'}>
                                <div className="about-one_image">
                                    <img src={abimg5} alt="" />
                                </div>
                            </Roll>
                        </div>
                    </div>
                    <div className="about-one_content-column col-lg-6 col-md-12 col-sm-12">
                        <div className="about-one_content-inner">
                            <div className="sec-title title-anim">
                                <div className="sec-title_title">This Season</div>
                                <h2 className="sec-title_heading">Our next event!</h2>
                            </div>
                            <div className="about-one_bold-text">{tournament.name}</div>
                            <div className="about-one_text">{tournament.description1}<br/> {tournament.description2} </div>
                            <div className="row clearfix">
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-eye"></span>
                                        <strong>Set Reveal</strong>
                                        {`${tournament.revealDate.day} ${tournament.revealDate.month} ${tournament.revealDate.date}`} <br/> {tournament.revealDate.time} <br/>
                                        <a className="text-primary" href="https://twitch.tv/setroulette" target="new">Twitch.tv/setroulette</a>
                                    </div>
                                </div>
                                <div className="about-one_block col-lg-6 col-md-6 col-sm-12">
                                    <div className="about-one_block-inner">
                                        <span className="about-one_block-icon flaticon-trophy"></span>
                                        <strong>Tournament</strong>
                                        {`${tournament.eventDate.day} ${tournament.eventDate.month} ${tournament.eventDate.date}`} <br/> {tournament.eventDate.time} <br/>
                                        <a className="text-primary" href="https://discord.com/invite/uC7bV9eFkm" target="new">Set Roulette Discord</a>
                                    </div>
                                </div>
                            </div>
                            <div className="about-one_button">
                                <a className='theme-btn btn-style-one' href={`https://melee.gg/Tournament/View/${tournament.id}`} target="new">
                                    <span className="btn-wrap">
                                        <span className="text-one">Register</span>
                                        <span className="text-two">Register</span>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="cR_D3vBhh6U" onClose={() => setOpen(false)} />
        </section>
    )
}

export default About;