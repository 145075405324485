import React from 'react'
import Sub1 from '../../images/background/6.jpg'


const Subscribe2 = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    return (
        <section className="subscribe-two" style={{ backgroundImage: `url(${Sub1})` }}>
            <div className="auto-container">
                <div className="inner-container">
                    <div className="row clearfix">
                        <div className="subscribe-two_title-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-two_title">Get The Latest Updates</div>
                            <h3 className="subscribe-two_heading">Get Subscribed Today!</h3>
                        </div>
                        <div className="form-column col-lg-6 col-md-12 col-sm-12">
                            <div className="subscribe-box style-two">
                                <form method="post" onSubmit={SubmitHandler}>
                                    <div className="form-group">
                                        <input type="email" name="search-field" value="" placeholder="Your Email Address ........" required />
                                        <button type="submit">Submit Now</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Subscribe2;