import React, { Fragment, useState } from 'react';
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
        submenu: [
            {
                id: 11,
                title: 'Homepage 01',
                link: '/home'
            },
            {
                id: 12,
                title: 'Homepage 02',
                link: '/home-2'
            },
            {
                id: 13,
                title: 'Homepage 03',
                link: '/home-3'
            },
            {
                id: 14,
                title: 'Home Onepage 01',
                link: '/home-onepage-1'
            },
            {
                id: 15,
                title: 'Home Onepage 02',
                link: '/home-onepage-2'
            },
            {
                id: 16,
                title: 'Home Onepage 03',
                link: '/home-onepage-3'
            },
        ]
    },

    {
        id: 4,
        title: 'About Us',
        link: '/about',
        submenu: [
            {
                id: 41,
                title: 'About Us',
                link: '/about',
            },
            {
                id: 42,
                title: 'Faq',
                link: '/faq'
            },
            {
                id: 43,
                title: 'Price',
                link: '/price'
            },
            {
                id: 44,
                title: 'Schedule',
                link: '/schedule'
            },
            {
                id: 46,
                title: 'Gallery',
                link: '/gallery'
            },
            {
                id: 47,
                title: 'Testimonial',
                link: '/testimonial'
            },
            {
                id: 48,
                title: 'Comming Soon',
                link: '/comming-soon"'
            },
            {
                id: 49,
                title: 'Terms & Condition',
                link: '/terms'
            },
            {
                id: 50,
                title: 'Privacy & Policy',
                link: '/privacy'
            }
        ]
    },

    {
        id: 6,
        title: 'Speakers',
        link: '/speaker',
        submenu: [
            {
                id: 61,
                title: 'Speakers',
                link: '/speaker'
            },
            {
                id: 62,
                title: 'Speaker Detail',
                link: '/speaker-details/Angelina-Holy'
            }
        ]
    },
    {
        id: 7,
        title: 'Events',
        link: '/event',
        submenu: [
            {
                id: 71,
                title: 'Events',
                link: '/event'
            },
            {
                id: 72,
                title: 'Events Detail',
                link: '/event-details/Modern-Marketing-Summit-Sydney'
            },
            {
                id: 73,
                title: 'Account',
                link: '/register'
            }
        ]
    },
    {
        id: 5,
        title: 'Blog',
        link: '/blog',
        submenu: [
            {
                id: 51,
                title: 'Our Blog',
                link: '/blog'
            },
            {
                id: 52,
                title: 'Blog classNameic',
                link: '/blog-classNameic'
            },
            {
                id: 53,
                title: 'Blog Left Sidebar',
                link: '/blog-left-sidebar'
            },
            {
                id: 54,
                title: 'Blog Single',
                link: '/blog-single/International-Conference-On-Art-Business'
            },
            {
                id: 55,
                title: 'Not Found',
                link: '/not-found'
            }
        ]
    },
    {
        id: 8,
        title: 'Contact',
        link: '/contact',
        submenu: [
            {
                id: 81,
                title: 'Contact 01',
                link: '/contact'
            },
            {
                id: 82,
                title: 'Contact 02',
                link: '/contact-2'
            },
            {
                id: 83,
                title: 'Contact 03',
                link: '/contact-3'
            }
        ]
    },


]

const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-menu"></span></div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-multiply"></span></div>
                <nav className="menu-box">
                    <div className="menu-outer">
                        <ul className="navigation">
                            {menus.map((item, mn) => {
                                return (
                                    <ListItem className={item.id === openId ? 'active' : null} key={mn}>
                                        {item.submenu ?
                                            <Fragment>
                                                <li>
                                                    <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>{item.title}
                                                        <i className={item.id === openId ? 'fa fa-angle-up' : 'fa fa-angle-down'}></i>
                                                    </p>
                                                </li>
                                                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                                                    <List className="subMenu">
                                                        <Fragment>
                                                            {item.submenu.map((submenu, i) => {
                                                                return (
                                                                    <ListItem key={i}>
                                                                        <Link onClick={ClickHandler} className="active"
                                                                            to={submenu.link}>{submenu.title}</Link>
                                                                    </ListItem>
                                                                )
                                                            })}
                                                        </Fragment>
                                                    </List>
                                                </Collapse>
                                            </Fragment>
                                            : <Link className="active"
                                                to={item.link}>{item.title}</Link>
                                        }
                                    </ListItem>
                                )
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MobileMenu;