// images
import blogImg1 from "../images/resource/news-1.jpg";
import blogImg2 from "../images/resource/news-2.jpg";
import blogImg3 from "../images/resource/news-3.jpg";
import blogImg4 from "../images/resource/news-4.jpg";
import blogImg5 from "../images/resource/news-5.jpg";
import blogImg6 from "../images/resource/news-6.jpg";

import blogSingleImg1 from "../images/resource/news-13.jpg";
import blogSingleImg2 from "../images/resource/news-14.jpg";
import blogSingleImg3 from "../images/resource/news-15.jpg";
import blogSingleImg4 from "../images/resource/news-13.jpg";
import blogSingleImg5 from "../images/resource/news-14.jpg";
import blogSingleImg6 from "../images/resource/news-15.jpg";

import Author1 from "../images/resource/author-1.jpg";
import Author2 from "../images/resource/author-2.jpg";



const blogs = [
    {
        id: '1',
        title: 'International Conference On Art Business',
        slug:'International-Conference-On-Art-Business',
        screens: blogImg1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg1, 
        comment:'35',
        thumb:'Event',
        blClass:'format-standard-image',
    },
    {
        id: '2',
        title: 'Unique Digital Ideas To Learn Business',
        slug:'Unique-Digital-Ideas-To-Learn-Business',
        screens: blogImg2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle:'Family Lawyer',
        create_at: '16 AUG,23',
        blogSingleImg:blogSingleImg2, 
        comment:'80',
        thumb:'Conference',
        blClass:'format-video',
    },
    {
        id: '3',
        title: 'Houston Event Planning Management',
        slug:'Houston-Event-Planning-Management',
        screens: blogImg3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle:'Business Lawyer',
        create_at: '18 AUG,23',
        blogSingleImg:blogSingleImg3,
        comment:'95',
        thumb:'Event',
        blClass:'format-standard-image',
    },
    {
        id: '4',
        title: 'International Conference On Art Business Speck',
        slug:'International-Conference-On-Art-Business-Speck',
        screens: blogImg4,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Villalpando',
        authorTitle:'Criminal Lawyer',
        create_at: '14 AUG,23',
        blogSingleImg:blogSingleImg4, 
        comment:'35',
        thumb:'Event',
        blClass:'format-standard-image',
        AuthorImg:Author1,
    },
    {
        id: '5',
        title: 'Unique Digital Ideas To Learn Business Growth',
        slug:'Unique-Digital-Ideas-To-Learn-Business-Growth',
        screens: blogImg5,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Konal Biry',
        authorTitle:'Family Lawyer',
        create_at: '16 AUG,23',
        blogSingleImg:blogSingleImg5, 
        comment:'80',
        thumb:'Conference',
        blClass:'format-video',
        AuthorImg:Author2,
    },
    {
        id: '6',
        title: 'Houston Event Planning Management For Us',
        slug:'Houston-Event-Planning-Management-For-Us',
        screens: blogImg6,
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.',
        author: 'Jenefer Willy',
        authorTitle:'Business Lawyer',
        create_at: '18 AUG,23',
        blogSingleImg:blogSingleImg6,
        comment:'95',
        thumb:'Event',
        blClass:'format-standard-image',
        AuthorImg:Author1,
    },
];

export default blogs;