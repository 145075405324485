import React, { useState } from 'react';
import { Link } from 'react-scroll'
// import { NavLink } from 'react-router-dom'


const MobileMenu2 = () => {
    const [menuActive, setMenuState] = useState(false);

    return (
        <div className={`mobile-menu-wrap ${menuActive ? "mobile-menu-visible" : ""}`}>
            <div className="mobile-nav-toggler" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-menu"></span></div>
            <div className="mobile-menu">
                <div className="menu-backdrop" onClick={() => setMenuState(!menuActive)}></div>
                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><span className="icon flaticon-multiply"></span></div>
                <nav className="menu-box">
                    <div className="menu-outer">
                        <ul className="navigation">
                            <li><Link activeClass="active" to="home" spy={true} smooth={true} duration={500} offset={-100}>Home</Link></li>
                            <li><a href='#about'> About </a></li>
                            <li><a href='#next'>Next Event</a></li>
                            <li><a href='#articles'>Articles</a></li>
                            <li><a href='#contact'>Contact</a></li>
                            <li><a href='#partners'>Partners</a></li>

                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}

export default MobileMenu2;