import React from "react";
// import { Link } from 'react-router-dom'
import pimg1 from '../../images/clients/tier1-logo.png'
// import pimg2 from '../../images/clients/2.png'
// import pimg3 from '../../images/clients/3.png'


const partners = [
    {
        pImg: pimg1,
        url:"www.setroulette.com/",
    },
]


const PartnerSection = (props) => {

    return (

        <section className="clients-one" id="partners">
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">Partners</div>
                    <h2 className="sec-title_heading">Thank You to our Partners</h2>
                </div>
                <div className="row clearfix centered">
                    {partners.map((partner, pitem) => (
                        // <div className="clients-one_column col-lg-4 col-md-4 col-sm-6" key={pitem}>
                        <div className="centered" key={pitem}>
                            <a target="new" href={partner.url}><img src={partner.pImg} alt="" target="new"/></a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default PartnerSection;