import React, { Fragment } from 'react';
import Navbar3 from '../../components/Navbar3/Navbar3';
import Hero from '../../components/hero/hero';
import About from '../../components/about/about';
import SetsSection from '../../components/Sets/SetsSection';
import Social from '../../components/Social';
import VideoArea from '../../components/VideoArea/VideoArea';
import PartnerSection from '../../components/PartnerSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Donate from '../../components/Donate'

const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
const dayNames = [
    "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"
]

const TOURNAMENT = {
    id: "100111",
    name: "Fall 2024",
    description1: "Set Roulette returns with six brand new sets and a wide open format.",
    description2: "Come compete for $300 worth of prizes! Six sets. Two weeks. No bans!",
    revealDate: (function () {
        const full = new Date("2024-11-16 12:00");
        return {
            month: monthNames[full.getMonth()],
            date: full.getDate(),
            day: dayNames[full.getDay()],
            year: full.getFullYear(),
            time: "12:00 PM EST",
        };
    })(),
    eventDate: (function () {
        const full = new Date("2024-12-01 11:00");
        return {
            month: monthNames[full.getMonth()],
            date: full.getDate(),
            day: dayNames[full.getDay()],
            year: full.getFullYear(),
            time: "11:00 AM EST",
        };
    })(),
    sets: [],
};

console.log(TOURNAMENT)

const HomePage = () => {
    return (
        <Fragment>
            <Navbar3 tournament={TOURNAMENT} />
            <Hero tournament={TOURNAMENT} />
            <VideoArea />
            <About tournament={TOURNAMENT} /> {/* event section*/}
            <Donate/>
            <SetsSection tournament = {TOURNAMENT} /> {/* Sets section*/}
            <Social />
            <PartnerSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    );
};
export default HomePage;