import React from 'react';
import { Link } from 'react-router-dom'
import about from '../../images/resource/author-4.jpg'
import blogs from '../../api/blogs'


const BlogSidebar = (props) => {

    const SubmitHandler = (e) => {
        e.preventDefault()
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <div className={`sidebar-side col-lg-4 col-md-12 col-sm-12 ${props.blLeft}`}>
            <aside className="sidebar sticky-top">
                <div className="sidebar-widget search-box">
                    <form method="post" onSubmit={SubmitHandler}>
                        <div className="form-group">
                            <input type="search" name="search-field" value="" placeholder="Search..." required />
                            <button type="submit"><span className="icon fa fa-search"></span></button>
                        </div>
                    </form>
                </div>
                <div className="sidebar-widget category-widget">
                    <div className="widget-content">
                        <h4 className="sidebar-widget_title">Categories</h4>
                        <ul className="category-list">
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Business<span>01</span></Link></li>
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Conference<span>05</span></Link></li>
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Digital<span>08</span></Link></li>
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Music<span>04</span></Link></li>
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Startup<span>03</span></Link></li>
                            <li><Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Workshop<span>07</span></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar-widget author-widget">
                    <div className="widget-content">
                        <div className="author-widget_image">
                            <img src={about} alt="" />
                        </div>
                        <div className="author-widget_name">Thomas Josaf</div>
                        <div className="author-widget_text">Hear 9 inspiring talks, meet the best product people in Europe, and party together after the event!</div>
                        <ul className="author-widget_socials">
                            <li><Link onClick={ClickHandler} to='/blog' className="fa fa-twitter"></Link></li>
                            <li><Link onClick={ClickHandler} to='/blog' className="fa fa-facebook"></Link></li>
                            <li><Link onClick={ClickHandler} to='/blog' className="fa fa-dribbble"></Link></li>
                            <li><Link onClick={ClickHandler} to='/blog' className="fa fa-linkedin"></Link></li>
                        </ul>
                    </div>
                </div>
                <div className="sidebar-widget post-widget">
                    <div className="widget-content">
                        <h4 className="sidebar-widget_title">Recent Post</h4>
                        {blogs.slice(0, 3).map((blog, Bitem) => (
                            <div className="post" key={Bitem}>
                                <div className="thumb"><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}><img src={blog.screens} alt="" /></Link></div>
                                <div className="post-date">18 July, 2022</div>
                                <h6><Link onClick={ClickHandler} to={`/blog-single/${blog.slug}`}>Take Your Life Into Your Own Hands</Link></h6>
                            </div>
                        ))}

                    </div>
                </div>
                <div className="sidebar-widget popular-tags">
                    <div className="widget-content">
                        <h4 className="sidebar-widget_title">Tag</h4>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Business</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Conference</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Digital</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Development</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Venue</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Tech</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Workshop</Link>
                        <Link onClick={ClickHandler} to="/blog-single/International-Conference-On-Art-Business" >Speaker</Link>
                    </div>
                </div>

            </aside>
        </div>
    )

}

export default BlogSidebar;
