import React from 'react'
import { Link } from 'react-router-dom'
import Teams from '../../api/SetsData'
import shape1 from '../../images/background/pattern-4.png'
import shape2 from '../../images/background/pattern-6.png'
// import shape3 from '../../images/background/pattern-5.png'

const SetsSection = ({tournament}) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <section className="speakers-one" id="next">
            <div className="speakers-one_pattern" style={{ backgroundImage: `url(${shape1})` }}></div>
            <div className="speakers-one_pattern-two" style={{ backgroundImage: `url(${shape2})` }}></div>
            <div className="auto-container">
                <div className="sec-title title-anim centered">
                    <div className="sec-title_title">{tournament.name}</div>
                    <h2 className="sec-title_heading">This season's sets</h2>
                    <p> Join us <strong> {`${tournament.revealDate.day} ${tournament.revealDate.month} ${tournament.revealDate.day}`}</strong> for our live set reveal on <strong><a target="new" href='https://twitch.tv/setroulette'>twitch.tv/setroulette</a></strong>.</p>
                </div>
                <div className="row clearfix set-container">
                    {Teams.slice(0, 6).map((team, aitem) => (
                        <div className="speaker-block_one col-lg-4 col-md-6 col-sm-12" key={aitem}>
                            <div className="speaker-block_one-inner">
                                <div className="speaker-block_one-image mx-auto">
                                    <img src={team.tImg} alt="" />
                                    <div className="speaker-block_one-pattern" style={{ backgroundImage: `url(${team.symbol})` }}></div>
                                </div>
                                <div className="speaker-block_one-content">
                                    <h4 className="speaker-one_title"><Link onClick={ClickHandler} to={`/speaker-details/${team.slug}`}>{team.name}</Link></h4>
                                    <div className="speaker-one_detail">{team.title}</div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default SetsSection;
