import React from 'react'
// import { Link } from 'react-router-dom'
import sIcon from '../../images/background/pattern-7.png'
import sIcon2 from '../../images/background/pattern-8.png'
import sIcon3 from '../../images/background/pattern-9.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitch, faTwitter} from '@fortawesome/free-brands-svg-icons'

const Social = (props) => {

    // const ClickHandler = () => {
    //     window.scrollTo(10, 0);
    // }

    const featres = [
        {
            fIcon: faDiscord,
            title: 'Discord',
            url: 'https://discord.com/invite/uC7bV9eFkm'
        },
        {
            fIcon: faTwitter,
            title: 'Twitter',
            url: 'https://x.com/setroulette'

        },
        {
            fIcon: faTwitch,
            title: 'Twitch',
            url: 'https://twitch.tv/setroulette'
        },
    ]


    return (
        <section className="feature-one" id="contact">
            <div className="feature-one_pattern" style={{ backgroundImage: `url(${sIcon})` }}></div>
            <div className="feature-one_pattern-two" style={{ backgroundImage: `url(${sIcon2})` }}></div>
            <div className="feature-one_pattern-three" style={{ backgroundImage: `url(${sIcon3})` }}></div>
            <div className="auto-container">
                <div className="sec-title title-anim">
                    <div className="sec-title_title">Social</div>
                    <h2 className="sec-title_heading">Join our community</h2>
                </div>
                <div className="row clearfix">
                    {featres.map((featres, fitem) => (
                        <div className="feature-block_one col-lg-4 col-md-4 col-sm-12" key={fitem}>
                            <div className="feature-block_one-inner centered">
                            <a href={featres.url} target="new" className='text-primary'>
                                <div className="feature-block_one-icon"><FontAwesomeIcon icon={featres.fIcon} /></div>
                                <h4 className="feature-block_one-title">{featres.title}</h4>
                            </a>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default Social;